<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-finalizar-viaje">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Finalizar Viaje</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="fecha_fin">Fecha Fin</label>
                <input
                  type="date"
                  v-model="form_viaje.fecha_fin"
                  style="font-size: 13px"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_viaje.fecha_fin.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
                <div class="error" v-if="!$v.form_viaje.fecha_fin.required">
                  Seleccione una fecha
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="hora_fin">Hora Fin</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="form_viaje.hora_fin"
                  :class="
                    $v.form_viaje.hora_fin.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  v-mask="{
                    mask: '99:99',
                    hourFormat: '24',
                  }"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form_viaje.$invalid"
              @click="save()"
            >
              Finalizar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "TepViajeInternoFinalizar",
  data() {
    return {
      form_viaje: {},
    };
  },
  validations: {
    form_viaje: {
      fecha_fin: {
        required,
      },
      hora_fin: {
        required,
        timeFormat,
      },
    },
  },
  methods: {
    save() {
      if (!this.$v.form_viaje.$invalid) {
        axios({
          method: "PUT",
          url: "/api/tep/viajes/finalizar",
          data: this.form_viaje,
        })
          .then((response) => {
            this.form_viaje = {};
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "Se Finalizo el viaje exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        this.$parent.getIndex();
      }
    },
    llenar_modal(viaje) {
      this.form_viaje = viaje;
    },
  },
};
</script>
