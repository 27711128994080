var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-finalizar-viaje"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Finalizar Viaje")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"fecha_fin"}},[_vm._v("Fecha Fin")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_viaje.fecha_fin),expression:"form_viaje.fecha_fin"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_viaje.fecha_fin.$invalid
                    ? 'is-invalid'
                    : 'is-valid',staticStyle:{"font-size":"13px"},attrs:{"type":"date"},domProps:{"value":(_vm.form_viaje.fecha_fin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form_viaje, "fecha_fin", $event.target.value)}}}),(!_vm.$v.form_viaje.fecha_fin.required)?_c('div',{staticClass:"error"},[_vm._v(" Seleccione una fecha ")]):_vm._e()]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"hora_fin"}},[_vm._v("Hora Fin")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_viaje.hora_fin),expression:"form_viaje.hora_fin"},{name:"mask",rawName:"v-mask",value:({
                  mask: '99:99',
                  hourFormat: '24',
                }),expression:"{\n                  mask: '99:99',\n                  hourFormat: '24',\n                }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_viaje.hora_fin.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.form_viaje.hora_fin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form_viaje, "hora_fin", $event.target.value)}}})])])]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form_viaje.$invalid),expression:"!$v.form_viaje.$invalid"}],staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Finalizar ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }